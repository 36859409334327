<template>
	<v-app>
		<v-main>
			<v-container class="fill-height" fluid >
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4" lg="3" xl="2">
						<v-card class="elevation-12" style="opacity:0.8">
							<v-toolbar :color="appSetup.appColor" dense dark flat>
								<v-toolbar-title>{{ appSetup.appName }} sso login</v-toolbar-title>
								<div class="flex-grow-1" />
							</v-toolbar>
							<v-card-actions>
								<div class="flex-grow-1" />
								<v-btn text color="primary" :to="{name: 'login'}">
									go to Standard Login
								</v-btn>
							</v-card-actions>
							<v-card-actions v-if="error">
								<v-row>
									<v-col cols="12">
										<v-alert text class="mb-0" type="error">
											{{ errorText }}
										</v-alert>
										<br>
										<v-alert class="mb-0" type="info" outlined small dense text>
											<small>
												Per problematiche di accesso potete contattare il nr 045 6704568 oppure scrivete un'email a <a href="mailto:skodasupercard@servicedirect.it ">skodasupercard@servicedirect.it </a>
											</small>
										</v-alert>
									</v-col>

								</v-row>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<loading :loading="loading" />
	</v-app>
</template>

<script>
import Loading from '@/components/loading'
export default {
	name: 'Sso',
	components: { Loading },
	props: {
		dark: Boolean
	},
	data: function () {
		return {
			txtGuid: null,
			loading: false,
			error: false,
			errorText: '',
		}
	},
	computed: {
		appSetup() { return window.setup },
		isLoggedIn() { return this.$store.getters['isLoggedIn'] }
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
			// this.txtGuid = this.$route.params.txtGuid
			// this.login()
		},
	},
	mounted: function () {
		// 
		this.txtGuid = this.$route.params.txtGuid
		this.login()
	},
	methods: {
		login() {
			this.error = false
			this.loading = true
			this.$store.dispatch('login', {TxtGuid: this.txtGuid}).then(() => {
				this.error = false
				this.loading = false
			}).catch((err) => {
				console.debug(err)
				this.error = true
				setTimeout(() => (this.loading = false), 200)
				if (err.message.indexOf('400') > 0 ) {
					if(err.response && err.response.data && err.response.data.Message && err.response.data.Message === 'Suspended')
						this.errorText = 'Account sospeso'
					else
						this.errorText = 'Username o Password errati'
				} else 
					this.errorText = err.message
			})
		}
	}
}
</script>
